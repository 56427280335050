<template>
    <v-card flat :class="`shepherd-assignment-intro-${bundleData.bid}`">
        <v-card-title>
            <div>
                <v-btn
                    rounded
                    class="mr-3"
                    :to="{
                        name: 'snapshot-assignments'
                    }">
                    <v-icon class="ml-n3 secondary--text">chevron_left</v-icon>
                    <v-icon class="secondary--text">assignment</v-icon>
                </v-btn>
                <span class="secondary--text font-weight-bold">{{ bundleData.long_id }}</span>
                <div v-if="!isSpaceAdmin && isAssignmentOverdue(bundleData.allow_handins_until)">
                    <span :class="`caption shepherd-handin-button-${bundleData.bid}`">Deadline has passed, no further hand-ins allowed</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn left @click="tour.start()" v-on="on" icon small><v-icon small>help_outline</v-icon></v-btn>
                        </template>
                        <span>Show tour</span>
                    </v-tooltip>
                </div>
            </div>
        </v-card-title>
        <v-container fluid>
            <v-row>
                <v-col cols="9">
                    <v-card>
                        <v-tabs v-model="tab" @change="tabChanged">
                            <v-tab>Handin ({{ bundleData.handins.length }})</v-tab>
                            <v-tab>Handback</v-tab>
                            <v-tab v-if="isSpaceAdmin">
                                <v-icon left>grading</v-icon>
                                Grades
                            </v-tab>
                            <v-spacer></v-spacer>

                            <div class="ma-3">
                                <v-btn :href="downloadURL" :loading="fetchingDLToken" :disabled="!downloadURL">
                                    <v-icon left>mdi-download</v-icon>
                                    Download
                                </v-btn>
                            </div>
                            <span
                                class="ma-3 ml-0"
                                :class="`shepherd-handin-button-${bundleData.bid}`"
                                v-if="!isSpaceAdmin && !isAssignmentOverdue(bundleData.allow_handins_until)">
                                <TheSnapshotAssignmentSubmitDialog :bundleData="bundleData" />
                            </span>
                            <div v-if="isSpaceAdmin" class="ma-3 ml-0">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-on="on">
                                            <v-btn
                                                color="primary"
                                                :disabled="!isAssignmentOverdue(bundleData.allow_handins_until)"
                                                @click="startGrading()"
                                                v-bind="attrs"
                                                class="ml-2"
                                                elevation="0">
                                                <v-icon left>grading</v-icon>
                                                Grade
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span v-if="isAssignmentOverdue(bundleData.allow_handins_until)">
                                        Assignment deadline has passed, grading can now be started.
                                    </span>
                                    <span v-else>Assignment grading can be started only after the due date has passed.</span>
                                </v-tooltip>
                            </div>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card-text>
                                    <v-container fluid>
                                        <v-row class="align-center">
                                            <v-col cols="12" v-if="bundleData.handins && bundleData.handins.length">
                                                <v-checkbox
                                                    v-model="gradeableCheckbox"
                                                    @change="gradeableCheckboxChanged"
                                                    label="Show only gradeable handins"
                                                    hide-details="auto"
                                                    class="mt-0"></v-checkbox>
                                            </v-col>
                                            <v-col>
                                                <div v-if="!bundleData.handins || bundleData.handins.length === 0">
                                                    No handins yet, please click the refresh button on the right to fetch them if you recently handed one in.
                                                </div>
                                                <v-select
                                                    v-else
                                                    outlined
                                                    dense
                                                    :items="handins"
                                                    v-model="handinSelect"
                                                    item-value="hid"
                                                    @change="fetchHandinObjects"
                                                    hide-details="auto">
                                                    <template v-slot:item="{ item }">
                                                        {{ item.long_id }} ({{ item.handin_timestamp | dateTimeToHuman }})
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        {{ item.long_id }} ({{ item.handin_timestamp | dateTimeToHuman }})
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="d-flex justify-end">
                                                    <v-btn @click="refreshHandins" small text outlined :loading="filesFetching">
                                                        <v-icon small left>refresh</v-icon>
                                                        Refresh
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mt-3" v-if="bundleData.handins && bundleData.handins.length">
                                            <v-col :class="`shepherd-assignment-files-${bundleData.bid}`">
                                                <FileList :apiId="handinSelect" :options="handinFileListOptions" />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-tab-item>

                            <!-- handbacks -->
                            <v-tab-item>
                                <v-card-text>
                                    <v-container fluid>
                                        <div v-if="grade && !isSpaceAdmin" class="mb-5 d-flex align-center">
                                            <div>
                                                <div class="secondary--text subtitle-1 font-weight-bold">Grade</div>
                                                Your grade for this assignment is
                                                <span class="font-weight-bold">{{ grade }}</span>
                                            </div>
                                            <v-spacer />
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" color="primary">
                                                        <v-icon left>visibility</v-icon>
                                                        Review Corrections
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        v-for="a in applications"
                                                        :key="a.aid"
                                                        :to="{
                                                            name: 'app-open',
                                                            params: {
                                                                oid: $route.params.oid,
                                                                sid: $route.params.sid,
                                                                iid: $route.params.iid,
                                                                snid: $route.params.snid,
                                                                aid: a.aid
                                                            },
                                                            query: { handback_bid: bundleData.bid }
                                                        }">
                                                        <v-list-item-title>{{ a.long_id }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                        <div
                                            v-else-if="!bundleData.handbacks_visible && isAssignmentOverdue(bundleData.allow_handins_until)"
                                            :class="{ 'pb-5': isSpaceAdmin }">
                                            This assignment is currently being graded and hand-backs / grades are not yet visible for submitters.
                                        </div>
                                        <div
                                            v-else-if="
                                                bundleData.handbacks_visible && isAssignmentOverdue(bundleData.allow_handins_until) && !grade && !isSpaceAdmin
                                            ">
                                            You haven't received a grade for this assignment.
                                        </div>
                                        <div v-else-if="!isAssignmentOverdue(bundleData.allow_handins_until) && !isSpaceAdmin">
                                            The assignment deadline has not yet passed, you can continue to hand-in solutions using the Hand-In button above.
                                        </div>
                                        <div v-else-if="isSpaceAdmin && !bundleData.handbacks_visible && !isAssignmentOverdue(bundleData.allow_handins_until)">
                                            No handbacks yet.
                                        </div>
                                        <template v-if="isSpaceAdmin || (bundleData.handbacks_visible && handbacks.length > 0)">
                                            <v-row class="mb-3">
                                                <v-col>
                                                    <v-select
                                                        outlined
                                                        dense
                                                        :items="handbacks"
                                                        v-model="handbackSelect"
                                                        item-value="hid"
                                                        @change="fetchHandbackObjects"
                                                        hide-details="auto">
                                                        <template v-slot:item="{ item }">
                                                            {{ item.long_id }} ({{ item.handin_timestamp | dateTimeToHuman }})
                                                        </template>
                                                        <template v-slot:selection="{ item }">
                                                            {{ item.long_id }} ({{ item.handin_timestamp | dateTimeToHuman }})
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col :class="`shepherd-assignment-files-${bundleData.bid}`">
                                                    <FileList :apiId="handbackSelect" :options="handbackFileListOptions" />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-container>
                                </v-card-text>
                            </v-tab-item>

                            <!-- grades -->
                            <v-tab-item>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <the-snapshot-assignment-grades
                                                    :bid="bundleData.bid"
                                                    :overdue="isAssignmentOverdue(bundleData.allow_handins_until)"
                                                    :bundleData="bundleData"
                                                    :startTour="startGradingTour" />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card flat class="transparent">
                        <v-alert type="info" text :class="`shepherd-assignment-description-${bundleData.bid}`">
                            <v-card-title class="pa-0">
                                <span class="font-weight-bold">Details</span>
                            </v-card-title>
                            <div v-if="bundleData.description">
                                {{ bundleData.description }}
                            </div>
                            <div v-if="bundleData.allow_handins_until" class="d-flex align-center my-2">
                                <v-icon left small color="info">alarm</v-icon>
                                Due: {{ bundleData.allow_handins_until | dateTimeToHuman }}
                            </div>
                            <div class="my-2">
                                <span left>Handback visibility:</span>
                                <v-icon color="info">{{ bundleData.handbacks_visible ? 'visibility' : 'visibility_off' }}</v-icon>
                            </div>
                            <div class="mt-1 mb-4 caption" v-if="bundleData.creation_timestamp">
                                <span>Created</span>
                                : {{ bundleData.creation_timestamp | dateTimeToHuman }}
                            </div>
                            <TheBundleEditDialog
                                v-if="isSpaceAdmin && bundleData.bundle_type_name === distributionReasons.ASSIGNMENT"
                                :bundleData="bundleData"
                                :showTooltip="false">
                                <template v-slot:dialogactivator>
                                    <v-btn outlined color="primary">
                                        <v-icon left>edit</v-icon>
                                        Edit
                                    </v-btn>
                                </template>
                            </TheBundleEditDialog>
                        </v-alert>

                        <v-subheader>
                            <span class="secondary--text font-weight-bold">Files ({{ bundleData.objects.files.length }})</span>
                        </v-subheader>
                        <v-list class="pa-0 transparent" dense>
                            <v-list-item style="max-height: 5px" v-for="file in bundleData.objects.files" :key="file" class="pa-0">
                                <v-list-item-avatar>
                                    <v-icon small>mdi-file-outline</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2">{{ file.slice(6) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-subheader>
                            <span class="secondary--text font-weight-bold">Applications ({{ bundleData.objects.applications.length }})</span>
                        </v-subheader>
                        <v-list class="pa-0 transparent" dense>
                            <v-list-item style="max-height: 5px" v-for="application in bundleData.objects.applications" :key="application" class="pa-0">
                                <v-list-item-avatar>
                                    <v-icon small>desktop_windows</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="secondary--text subtitle-2">{{ application }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="errDialog" max-width="50%" persistent>
            <v-card>
                <v-card-title>Permission error</v-card-title>
                <v-card-text>
                    You have
                    <v-chip outlined small color="warning">VIEWER</v-chip>
                    role in this instance, thus does not have the permission to view this. We will guide you back to and instance where you have at least
                    <v-chip outlined small color="primary">EDITOR</v-chip>
                    role.
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Shepherd from 'shepherd.js'
import tour from '@/mixins/tour'
import { assignmentMethods } from '@/mixins/assignments'
import { enumsData } from '@/mixins/enums'
import { mapGetters, mapState } from 'vuex'
import { get } from 'lodash'

const TheSnapshotAssignmentSubmitDialog = () => import('../components/TheSnapshotAssignmentSubmitDialog.vue')
const TheSnapshotAssignmentGrades = () => import('../components/TheSnapshotAssignmentGrades.vue')
const TheBundleEditDialog = () => import('../components/TheSnapshotBundleEditDialog')
const FileList = () => import('../../space/components/FileList.vue')

export default {
    name: 'NuvolosAssignment',
    mixins: [assignmentMethods, enumsData, tour],
    components: {
        TheSnapshotAssignmentGrades,
        TheSnapshotAssignmentSubmitDialog,
        TheBundleEditDialog,
        FileList
    },
    data() {
        return {
            tab: 0,
            grade: undefined,
            tour: null,
            files: null,
            handinFileListOptions: {
                stage: false,
                rename: false,
                move: false,
                edit: false,
                copy: false,
                duplicate: false,
                delete: false,
                view: true
            },
            handbackFileListOptions: {
                stage: false,
                rename: false,
                move: false,
                edit: false,
                copy: false,
                duplicate: false,
                delete: false,
                view: true
            },
            handinSelect: null,
            handbackSelect: null,
            maxHid: -1,
            handins: [],
            handbacks: [],
            gradeableCheckbox: true,
            refresClicked: false,
            errDialog: false,
            startGradingTour: false,
            fetchingDLToken: false,
            downloadToken: '',
            downloadURL: ''
        }
    },
    computed: {
        ...mapGetters('instanceStore', ['isMasterInstance', 'isDistributedInstance']),
        ...mapGetters('spaceStore', ['isSpaceAdmin']),
        ...mapState('snapshotStore', ['applications', 'filesFetching']),
        ...mapState(['userOrgs', 'userMetadata']),
        ...mapState(['userInfo']),
        ...mapState('spaceStore', ['spaceBundles', 'fetchingSpaceBundles', 'spaceInstances', 'fetchingInstances']),
        showAssignmentTour() {
            return !this.isSpaceAdmin && !this.isAssignmentOverdue(this.bundleData.allow_handins_until)
        },
        bundleData() {
            return this.spaceBundles.find(b => b.bid.toString() === this.$route.params.bid.toString())
        },
        fetchingAll() {
            return this.fetchingSpaceBundles || this.fetchingInstances
        }
    },
    created() {
        this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
    },
    mounted() {
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true
                },
                classes: 'secondary--text',
                scrollTo: { behavior: 'smooth' }
            }
        })

        this.tour.addStep({
            title: 'Welcome to assignments!',
            text: `Working with assignments on Nuvolos is easy! \
  We will guide you through the most important steps.`,
            id: 'grading-intro',
            buttons: [
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ]
        })

        this.tour.addStep({
            title: 'The assignment overview',
            text: `This dialog shows you the most important information about the assignment. \
  Let's walk through it!`,
            attachTo: {
                element: `.shepherd-assignment-intro-${this.bundleData.bid}`,
                on: 'bottom'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-intro'
        })

        this.tour.addStep({
            title: 'Assignment description',
            text: `Here is the quick summary of the assignment. \
            You can only submit solutions before the due-date!`,
            attachTo: {
                element: `.shepherd-assignment-description-${this.bundleData.bid}`,
                on: 'top'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-description'
        })

        this.tour.addStep({
            title: 'Assignment files',
            text: `Here are the files / folders for the assignment. \
            You are expected to work inside these files / folders, <b>do not rename or move</b> them!`,
            attachTo: {
                element: `.v-window-item--active .shepherd-assignment-files-${this.bundleData.bid}`,
                on: 'top'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-files'
        })

        this.tour.addStep({
            title: 'Handing-in assignments',
            text: `Once you are ready with editing the assignment files \
            click the hand-in button to submit them to the instructor for evaluation.`,
            attachTo: {
                element: `.shepherd-handin-button-${this.bundleData.bid}`,
                on: 'left'
            },
            buttons: [
                {
                    action() {
                        return this.back()
                    },
                    classes: 'shepherd-button-primary',
                    text: 'Back'
                },
                {
                    action() {
                        return this.next()
                    },
                    text: 'Next'
                }
            ],
            id: 'assignment-handin'
        })

        this.tour.addStep({
            title: 'Instructor feedback',
            text: `Once the instructor has graded the assignment, come back here to check your grade and corrections.`,
            attachTo: {
                element: `.shepherd-assignment-intro-${this.bundleData.bid}`,
                on: 'bottom'
            },
            buttons: [
                {
                    action() {
                        return this.complete()
                    },
                    text: 'Got it!'
                }
            ],
            id: 'assignment-feedback'
        })
    },
    methods: {
        startGrading() {
            this.tab = 2
            this.startGradingTour = true
        },
        fetchHandinObjects(hid) {
            this.$store.dispatch('snapshotStore/setTreeLevel', { snid: hid, level: 0 })
            this.$router.push({ name: 'handin', params: { hid } })
            this.updateDLTokens(hid)
        },
        fetchHandbackObjects(hid) {
            this.$store.dispatch('snapshotStore/setTreeLevel', { snid: hid, level: 0 })
            this.$router.push({ name: 'handback', params: { hid } })
            this.updateDLTokens(hid)
        },
        tabChanged(tabIndex) {
            if (!this.bundleData.handins || this.bundleData.handins.length === 0) return
            const hid = tabIndex === 1 ? this.maxHid : this.$route.params.hid
            const name = tabIndex === 1 ? 'handback' : 'handin'
            this.$router.push({ name, params: { hid } })

            this.handinSelect = +hid
            this.handbackSelect = +hid
            this.$store.dispatch('snapshotStore/setTreeLevel', { snid: hid, level: 0 })
            this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                id: hid,
                route: this.$route,
                setFetchingStatus: true
            })
            this.updateDLTokens(hid)
        },
        refreshHandins() {
            this.refresClicked = true
            this.$store.dispatch('spaceStore/fetchSpaceBundles', this.$route.params.sid)
        },
        gradeableCheckboxChanged(state) {
            this.handins = state ? this.bundleData.handins.filter(h => h.is_latest) : this.bundleData.handins
            this.handinSelect = this.handins[0].hid

            this.$router.push({ name: 'handin', params: { hid: this.handinSelect } })
            this.$store.dispatch('snapshotStore/setTreeLevel', { snid: this.handinSelect, level: 0 })
            this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                id: this.handins[0].hid,
                route: this.$route,
                setFetchingStatus: true
            })
        },
        async updateDLTokens(hid) {
            this.fetchingDLToken = true
            try {
                const fetchingToken = await this.$axios.get(`/handins/${hid}/download_link`)
                this.downloadToken = fetchingToken.data
                this.downloadURL = `${this.$axios.defaults.baseURL}/downloads/${fetchingToken.data}`
            } catch {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not get download URLs',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
                this.downloadURL = ''
            }
            this.fetchingDLToken = false
        }
    },
    watch: {
        showAssignmentTour(to) {
            if (to) {
                this.$nextTick(() => {
                    if (!this.userMetadata.has_seen_assignment_student_intro) {
                        this.$store.commit('setUserMetadata', { has_seen_assignment_student_intro: true })
                        this.tour.start()
                    }
                })
            } else {
                this.tour.complete()
            }
        },
        fetchingAll: {
            immediate: true,
            handler(fetching) {
                if (!fetching) {
                    const currentInstance = this.spaceInstances.find(instance => instance.iid === +this.$route.params.iid)
                    if (currentInstance.role === this.roleTypes.INSTANCE_VIEWER) {
                        this.errDialog = true
                        const editorInstances = this.spaceInstances.filter(instance => instance.role === this.roleTypes.INSTANCE_EDITOR)
                        let routeToInstance = editorInstances.find(instance => instance.long_id === this.userInfo.email)
                        if (!routeToInstance) {
                            routeToInstance = editorInstances[0]
                        }
                        setTimeout(() => {
                            this.$router.push({
                                name: 'snapshot-overview',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: routeToInstance.iid,
                                    snid: routeToInstance.dev_snid
                                }
                            })
                        }, 5000)
                    } else {
                        if (!this.bundleData.handins || this.bundleData.handins.length === 0) return
                        this.handins = this.gradeableCheckbox ? this.bundleData.handins.filter(h => h.is_latest) : this.bundleData.handins
                        this.handbacks = this.bundleData.handins.filter(h => h.is_latest)
                        // get hid from url
                        const urlHid = +get(this.$route, 'params.hid')
                        // get maxHid for handback
                        this.maxHid = this.handins.find(handin => handin.is_latest).hid
                        // set hid and name depending on url / handin / handback
                        let hid = this.handins[0].hid
                        if (this.$route.name === 'handin') {
                            hid = !this.refresClicked ? urlHid || this.handins[0].hid : this.handins[0].hid
                            this.refresClicked = false
                        }
                        if (this.$route.name === 'handback') {
                            hid = urlHid || this.maxHid
                            this.tab = 1
                        }
                        const name = this.$route.name === 'handback' ? 'handback' : 'handin'
                        this.handbackSelect = hid
                        this.handinSelect = hid
                        this.downloadToken = this.updateDLTokens(hid)
                        // go to handin or handback with appropriate params
                        this.$router.push({ name, params: { hid, localPath: this.$route.params.localPath } })
                        this.$axios.get(`/bundles/${this.bundleData.bid}/instance/${this.$route.params.iid}/grade`).then(response => {
                            this.grade = response.data
                        })
                    }
                }
            }
        }
    }
}
</script>
